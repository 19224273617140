<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>商品一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="productBulkEditForm" lazy-validation>
        <v-container>
          <section-block>
            <v-row v-for="(source, index) in settingSources" :key="index" dense>
              <v-col cols="3">
                <v-select
                  :items="constant.productBulkEditColumnsValues"
                  label="設定項目"
                  v-model="source.column"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @change="onSettingColSelected(index, source.column)"
                  dense
                  filled
                  attach
                >
                </v-select>
              </v-col>
              <v-col cols="5">
                <v-select
                  v-if="source.column === constant.productBulkEditColumns.PRODUCT_TYPE"
                  :items="productTypes"
                  label="商品区分"
                  v-model="source.value"
                  dense
                  filled
                  attach
                  :rules="[rules.required, rules.validSelectColumn]"
                ></v-select>
                <category-field
                  ref="category"
                  v-if="source.column === constant.productBulkEditColumns.CATEGORY_NAME"
                  v-model="source.value"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @selected="onCategoryChanged"
                ></category-field>
                <product-title-field
                  v-if="source.column === constant.productBulkEditColumns.TITLE"
                  v-model="source.value"
                  filled
                  attach
                  :rules="[rules.required, rules.validSelectColumn, rules.maxByteLengthSjis(60)]"
                ></product-title-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.SALES_ORIGIN_NAME"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.required, rules.validSelectColumn, rules.maxLength(30)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.SALES_AGENCY_NAME"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.required, rules.maxLength(30), rules.validSelectColumn]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.RETAIL_PRICE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required, rules.isNumber, rules.maxLength(10)]"
                  @change="source.value = stringToNumber($event)"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.PURCHASE_RATE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required, rules.isRate, rules.maxRateLength(3, 1)]"
                  @change="source.value = stringToNumber($event)"
                  filled
                  dense
                >
                </v-text-field>
                <v-select
                  v-if="source.column === constant.productBulkEditColumns.CONSUMPTION_TAX_RATE"
                  label="設定値"
                  :items="constant.consumptionTaxRates"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                  filled
                  dense
                ></v-select>
                <v-textarea
                  v-if="source.column === constant.productBulkEditColumns.PRODUCT_DETAIL"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(300), rules.validSelectColumn]"
                  filled
                  dense
                  rows="1"
                >
                </v-textarea>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.CAFEREO_CLOSING_DATE"
                  type="date"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.RELEASE_DATE"
                  type="about"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.BAN_DATETIME"
                  type="datetime"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <v-switch
                  v-if="source.column === constant.productBulkEditColumns.STOCK_STATUS"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn]"
                ></v-switch>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.ORDER_UNIT"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.UNIT"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.IN_CT_BOX_QUANTITY"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.IN_CT_PCS_QUANTITY"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-select
                  v-if="source.column === constant.productBulkEditColumns.PRODUCT_RANK"
                  :items="constant.productRanks"
                  label="商品ランク"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                  attach
                ></v-select>
                <v-textarea
                  v-if="source.column === constant.productBulkEditColumns.CAFEREO_REMARKS"
                  label="設定値"
                  v-model="source.value"
                  rows="1"
                  :rules="[rules.maxLength(300), rules.validSelectColumn]"
                >
                </v-textarea>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.RESALE_RETAIL_PRICE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required, rules.isNumber, rules.maxLength(10)]"
                  @change="source.value = stringToNumber($event)"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.RESALE_PURCHASE_RATE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required, rules.isRate, rules.maxRateLength(3, 1)]"
                  @change="source.value = stringToNumber($event)"
                  filled
                  dense
                >
                </v-text-field>
                <v-textarea
                  v-if="source.column === constant.productBulkEditColumns.RESALE_PRODUCT_DETAIL"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(300), rules.validSelectColumn]"
                  filled
                  dense
                  rows="1"
                >
                </v-textarea>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.RESALE_CAFEREO_CLOSING_DATE"
                  type="date"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.RESALE_RELEASE_DATE"
                  type="about"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <dp-date-picker
                  v-if="source.column === constant.productBulkEditColumns.RESALE_BAN_DATETIME"
                  type="datetime"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.required]"
                ></dp-date-picker>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.RESALE_ORDER_UNIT"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.productBulkEditColumns.RESALE_UNIT"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.validSelectColumn, rules.isNumber, rules.maxLength(8)]"
                  @change="source.value = stringToNumber($event)"
                >
                </v-text-field>
                <v-radio-group
                  v-if="source.column === constant.productBulkEditColumns.SCOPE_TYPE"
                  v-model="source.value"
                  :rules="[rules.required, rules.validSelectColumn]"
                  row
                  @change="scopedCustomersChanged"
                >
                  <v-radio
                    :label="scope.text"
                    :value="scope.value"
                    v-for="scope in constant.scopeTypes"
                    :key="scope.value"
                  ></v-radio>
                </v-radio-group>
                <v-autocomplete
                  v-if="source.column === constant.productBulkEditColumns.SCOPE_TYPE && source.value != ScopeTypes.NONE"
                  :items="scopeCustomerCorporations"
                  item-text="corporationName"
                  item-value="corporateCode"
                  label="会社名"
                  dense
                  filled
                  multiple
                  v-model="scopeCorporateCodes"
                  attach
                ></v-autocomplete>
                <v-row
                  dense
                  v-if="
                    source.column === constant.productBulkEditColumns.SCOPE_TYPE && source.value === ScopeTypes.WHITE
                  "
                >
                  <v-col cols="12" sm="3">
                    <v-btn v-model="updateModel.tohoLimited" :disabled="btnDisabled" @click="onAppendTohoLimited" dense
                      >東方のため流通限定</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row
                  dense
                  v-if="
                    source.column === constant.productBulkEditColumns.SCOPE_TYPE && source.value === ScopeTypes.BLACK
                  "
                >
                  <v-col cols="12" sm="3">
                    <v-btn
                      v-model="updateModel.overseasImpossible"
                      @click="onAppendOverseasImpossible"
                      :disabled="btnDisabled"
                      dense
                      >海外不可</v-btn
                    >
                  </v-col>
                </v-row>
                <v-col
                  cols="12"
                  style="height: 400px"
                  v-if="source.column === constant.productBulkEditColumns.SCOPE_TYPE && source.value != ScopeTypes.NONE"
                >
                  <ag-grid-vue
                    id="ScopedCustomerSelector"
                    class="ag-theme-alpine"
                    style="height: 100%"
                    :gridOptions="scopeGridOptions"
                    :rowData="scopedCustomerRecords"
                  ></ag-grid-vue>
                </v-col>
              </v-col>

              <v-col cols="1"
                ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                  <v-icon dark> mdi-minus </v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn x-small fab dense @click="onAddSettingSource">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </section-block>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="selectedRows.length == 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetUpdate"
                >一括反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                id="myGrid"
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :enableCellTextSelection="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @column-everything-changed="onColumnEverythingChanged"
                @selection-changed="onSelectionChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.productCd"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                style="height: 100%"
                @cell-editing-started="onCellEditingStarted"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="発注一括編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>
<style>
/*
.ag-theme-alpine .ag-paging-panel {
  height: 170px;

}
*/
.resale-grid-header-class {
  background-color: #dddddd !important;
}
</style>
<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import BaseProductStatus from "../../consts/productStatus/BaseProductStatus";
import { ProductClassCellRenderer } from "../../models/ag-grid/cellRenderers";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCustomDateColumn,
} from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  AboutDateCellEditor,
  DateTimeCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import {
  CafereoEditable as CafereoColumnDefs,
  SupplierEditable as SupplierColumnDefs,
} from "../../consts/columns/ProductColumns";
import {
  CafereoProductBulkEditColumns,
  SupplierProductBulkEditColumns,
} from "../../consts/editColumns/ProductBulkEditColumns";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import ProductTypes from "../../consts/ProductTypes";
import ProductTitleField from "../../components/common/fields/ProductTitleField.vue";
import CategoryField from "../../components/common/fields/CategoryField.vue";
import ConsumptionTaxRates from "../../consts/ConsumptionTaxRates";
import ProductRanks from "../../consts/ProductRanks";
import ScopeTypes from "../../consts/ScopeTypes";
import ConvertUtils from "../../utils/ConvertUtils";
import { getColumnDef } from "../../models/ag-grid/helpers";

export default {
  name: "PurchaseBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
    AboutDateCellEditor,
    DateTimeCellEditor,
    NumericCellEditor,
    SectionBlock,
    ProductTypes,
    ProductTitleField,
    CategoryField,
  },
  data() {
    const self = this;
    return {
      updateModel: {},
      defaultModel: {
        productType: null,
        categoryId: null,
        title: null,
        salesOriginName: null,
        salesAgencyName: null,
        retailPrice: null,
        purchaseRate: null,
        consumptionTaxRate: null,
        productDetail: null,
        orderClosingDate: null,
        releaseDate: null,
        banDatetime: null,
        stockStatus: null,
        orderUnit: null,
        unit: null,
        productRank: null,
        ScopetType: null,
        cafereoRemarks: null,
      },
      constant: {
        productBulkEditColumnsValues: [],
        productBulkEditColumns: [],
        deliveryCompanyList: DeliveryCompanys.all(),
        consumptionTaxRates: ConsumptionTaxRates.all(),
        productRanks: ProductRanks.all(),
        scopeTypes: ScopeTypes.all(),
      },
      ScopeTypes: ScopeTypes,
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      titleList: [],
      categoryList: [],
      settingSources: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableCustomDateColumn: EditableCustomDateColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
      },
      errorColmuns: [
        { headerName: "JANCODE", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      stringToNumber: ConvertUtils.stringToNumber,
      errorRows: [],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isRate: Validation.isRate,
        maxLength: Validation.maxLength,
        maxRateLength: Validation.maxRateLength,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
      categoryNames: "",
      height: "600px",
      width: "100%",
      scopeCorporateCodes: [],
      scopeCustomerCorporations: [],
      scopedCustomerRecords: [],
      scopedCustomers: [],
      btnDisabled: false,
      scopeGridOptions: {
        defaultColDef: {
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
        },
        columnDefs: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 50,
          },
          { headerName: "法人名", field: "corporationName", width: 300 },
          { headerName: "取引先名", field: "customerName", width: 300 },
        ],
        alwaysShowHorizontalScroll: true,
        suppressCellSelection: true,
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.customerCd,
        onRowDataChanged: (event) => {
          // 海外不可ボタンなどの自動チェック処理
          event.api.forEachNode((node) => {
            node.setSelected(false);
            for (let i = 0; i < self.scopedCustomers.length; i++) {
              if (node.data.customerCd == self.scopedCustomers[i]) node.setSelected(true);
            }
          });
        },
        onRowSelected(event) {
          if (event.node.isSelected()) {
            if (!self.scopedCustomers.includes(event.data.customerCd)) {
              self.scopedCustomers.push(event.data.customerCd);
            }
          } else {
            var index = self.scopedCustomers.indexOf(event.data.customerCd);
            self.scopedCustomers.splice(index, 1);
          }
        },
      },
      searchFlg: false,
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    async scopeCorporateCodes(value) {
      if (value) {
        await this.findCustomers(value);
        //グリッドから消された取引先の選択は外す
        let deleteList = [];
        this.scopedCustomers.forEach((e) => {
          let deleteFlg = true;
          this.scopeGridOptions.api.forEachNode((node) => {
            if (node.data.customerCd == e) {
              deleteFlg = false;
            }
          });
          if (deleteFlg) {
            deleteList.push(e);
          }
        });
        this.scopedCustomers = this.scopedCustomers.filter(function (v) {
          return !deleteList.includes(v);
        });
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    productTypes() {
      return ProductTypes.all();
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    async setColumnDefs() {
      try {
        this.titleList = [];
        this.customerMap = {};
        const response = await this.$store.dispatch("product/searchTitleList");
        if (response.data?.contents?.titles) {
          var titles = response.data.contents.titles;
          for (let i = 0; i < titles.length; i++) {
            const title = titles[i];
            const titleInfo = {
              value: title,
              label: title,
            };
            this.titleList.push(titleInfo);
          }
        }
      } catch (error) {
        console.error("ProductBulkEdit::setColumnDefs searchTitleList", error);
        this.apiRequestError(error);
      }
      try {
        this.categoryList = [];
        this.customerMap = {};
        const categoryResponse = await this.$store.dispatch("category/search");
        if (categoryResponse.data?.contents?.categorys) {
          var categorys = categoryResponse.data.contents.categorys;
          for (let i = 0; i < categorys.length; i++) {
            const category = categorys[i];
            const categoryInfo = {
              value: category.categoryId,
              label: category.categoryName,
            };
            this.categoryList.push(categoryInfo);
          }
        }
      } catch (error) {
        console.error("ProductBulkEdit::setColumnDefs category/search", error);
        this.apiRequestError(error);
      }
      if (this.isCafereoUser) {
        try {
          const params = { customerFlg: true };
          const response = await this.$store.dispatch("corporation/suggest", params);
          this.scopeCustomerCorporations = response.data.contents.corporations;
          // 公開範囲販売店の取得（この後はupdateModel.scopeCorporateCodesをwatchして更新）
          if (this.scopeCorporateCodes) {
            await this.findCustomers(this.scopeCorporateCodes);
          }
        } catch (error) {
          console.error("ProductBulkEdit::setColumnDefs corporation/suggest", error);
          this.apiRequestError(error);
        }
      }

      this.scopedCustomers = [];
      this.scopeCorporateCodes = [];
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: ProductClassCellRenderer({
            corporateType: this.$store.getters["security/loggedInUser"].corporateType,
          }),
        },
      ];
      if (this.isCafereoUser) {
        columnDefs = columnDefs.concat(CafereoColumnDefs);
        // ag-grid内のvalidation等をセット
        getColumnDef(columnDefs, "retailPrice").valueSetter = await this.valueSetter4RetailPrice; // 上代）仕入価格計算
        getColumnDef(columnDefs, "purchaseRate").valueSetter = await this.valueSetter4RetailPrice; // 仕入掛率）仕入価格計算

        getColumnDef(columnDefs, "resaleRetailPrice").valueSetter = await this.valueSetter4ResaleRetailPrice; // 上代）仕入価格計算
        getColumnDef(columnDefs, "resalePurchaseRate").valueSetter = await this.valueSetter4ResaleRetailPrice; // 再販仕入掛率）仕入価格計算
        getColumnDef(columnDefs, "resaleProductDetail").valueSetter = await this.valueSetter4Resales;
        getColumnDef(columnDefs, "resaleOrderClosingDate").valueSetter = await this.valueSetter4Resales;
        getColumnDef(columnDefs, "resaleReleaseDate").valueSetter = await this.valueSetter4Resales;
        getColumnDef(columnDefs, "resaleOrderUnit").valueSetter = await this.valueSetter4Resales;
        getColumnDef(columnDefs, "resaleUnit").valueSetter = await this.valueSetter4Resales;
        getColumnDef(columnDefs, "resaleBanDatetime").valueSetter = await this.valueSetter4Resales;
      } else {
        columnDefs = columnDefs.concat(SupplierColumnDefs);
      }
      getColumnDef(columnDefs, "banDatetime").valueSetter = this.valueSetterBanDatetime; // 情報解禁日時が変更可能か
      getColumnDef(columnDefs, "title").cellEditorParams = this.cellEditorParamsTitle(); // タイトルサジェスト
      getColumnDef(columnDefs, "categoryId").cellEditorParams = this.cellEditorParamsCategoryName(); // カテゴリサジェスト

      return columnDefs;
    },
    async findCustomers(corporateCodes) {
      try {
        this.loadingOn();
        this.scopedCustomerRecords = [];
        for (let i = 0; i < corporateCodes.length; i++) {
          const response = await this.$store.dispatch("customer/suggest", { CorporationCd: corporateCodes[i] });
          if (response.data.contents.customers)
            this.scopedCustomerRecords = this.scopedCustomerRecords.concat(response.data.contents.customers);
        }
      } catch (error) {
        console.error("UserEntry::findCustomers", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    valueSetter4RetailPrice(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error(params.colDef.headerName + "は" + isNumber, { timeout: 2300 });
        return false;
      } else {
        if (params.colDef.field == "purchaseRate") {
          params.data.purchaseRate = params.newValue;
        } else {
          // colDef.fieldが上代の場合
          params.data.retailPrice = params.newValue;
        }

        this.computePurchasePrice(params.data);
        return true;
      }
    },
    valueSetter4Resales(params) {
      if (
        params.data.resales.length <= 1 ||
        params.data.resales[params.data.resales.length - 2].productStatus >= BaseProductStatus.ADDITIONALRECEPTION
      ) {
        this.$dialog.notify.error(params.colDef.headerName + "は入荷済みまたは存在しないため編集できません。", {
          timeout: 2300,
        });
        return false;
      }
      params.data[params.colDef.field] = params.newValue;
    },
    valueSetter4ResaleRetailPrice(params) {
      if (
        params.data.resales.length <= 1 ||
        params.data.resales[params.data.resales.length - 2].productStatus >= BaseProductStatus.ADDITIONALRECEPTION
      ) {
        this.$dialog.notify.error(params.colDef.headerName + "は入荷済みまたは存在しないため編集できません。", {
          timeout: 2300,
        });
        return false;
      }
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error(params.colDef.headerName + "は" + isNumber, { timeout: 2300 });
        return false;
      } else {
        if (params.colDef.field == "resalePurchaseRate") {
          params.data.resalePurchaseRate = params.newValue;
        } else {
          // colDef.fieldが上代の場合
          params.data.resaleRetailPrice = params.newValue;
        }

        this.computeResalePurchasePrice(params.data);
        return true;
      }
    },
    valueSetterBanDatetime(params) {
      if (params.data.productStatus == BaseProductStatus.APPROVED) {
        params.data.banDatetime = params.newValue;
        return true;
      } else {
        this.$dialog.notify.error("ステータスが公開前で無いため情報解禁日は変更できません。", { timeout: 2300 });
        return false;
      }
    },
    cellEditorParamsTitle() {
      return {
        selectData: this.titleList,
        placeholder: "タイトル",
        required: false,
        autocomplete: {
          showOnFocus: true,
          strict: false,
        },
      };
    },
    cellEditorParamsCategoryName() {
      return {
        selectData: this.categoryList,
        placeholder: "カテゴリ",
        required: true,
        autocomplete: {
          showOnFocus: true,
        },
      };
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(inputModel) {
      this.updateModel = this.defaultModel;
      inputModel = JSON.parse(JSON.stringify(inputModel));
      inputModel.forEach((input) => {
        let tmp = JSON.parse(input.resales);
        let resales = [];
        tmp?.forEach((row) => {
          resales.push(this.toCamelCaseObject(row));
        });
        input.resales = resales;
        console.log(resales);
        if (resales.length > 1) {
          input.resaleProductName = resales[resales.length - 2].productName;
          input.resaleProductDetail = resales[resales.length - 2].productDetail;
          input.resaleRetailPrice = resales[resales.length - 2].retailPrice;
          input.resalePurchasePrice = resales[resales.length - 2].purchasePrice;
          input.resalePurchaseRate = resales[resales.length - 2].purchaseRate;
          input.resaleOrderClosingDate = resales[resales.length - 2].orderClosingDate;
          input.resaleReleaseDate = resales[resales.length - 2].releaseDate;
          input.resaleOrderUnit = resales[resales.length - 2].orderUnit;
          input.resaleUnit = resales[resales.length - 2].unit;
          input.resaleBanDatetime = resales[resales.length - 2].banDatetime;
        }
      });
      this.gridApi.setRowData(inputModel);
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.productBulkEditForm) {
        this.$refs.productBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onBulkEdit", this.searchFlg);
    },
    async onUpdateClick() {
      this.loadingOn();
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode((record) => {
        console.log(record.data);
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            jancode: record.data.jancode,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          // var param = {
          //   products: requestRecords,
          // };
          // console.log(param);
          const response = await this.$store.dispatch("product/bulkupdate", requestRecords);
          let error = response.data?.header;
          // 一覧更新レコード
          this.searchFlg = true;
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    jancode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              this.gridApi.forEachNode((row) => {
                let isError = this.errorRows.some((r) => r.jancode === row.data.jancode);
                if (!isError) {
                  updateRows.push(row.data);
                }
              });
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`商品情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`商品情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("onBulkEdit", true);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          this.loadingOff();
          console.error("ProductBulkEdit_update::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          // emit先のloadingが表示されなくなるのでここではloadingOffしない。
          //this.loadingOff();
        }
      } else {
        this.loadingOff();
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    requestFormat(row) {
      row.productCd = String(row.productCd);
      row.productType = Number(row.productType);
      row.title = row.title.label
        ? String(row.title.label)
        : typeof row.title === "string" || row.title instanceof String
        ? row.title
        : "";
      row.categoryId = row.categoryId.value ? String(row.categoryId.value) : String(row.categoryId);
      row.salesOriginName = String(row.salesOriginName);
      row.salesAgencyName = String(row.salesAgencyName);
      row.retailPrice = Number(row.retailPrice);
      row.purchasePrice = Number(row.purchasePrice);
      row.purchaseRate = Number(row.purchaseRate);
      row.consumptionTaxRate = Number(row.consumptionTaxRate);
      row.productDetail = String(row.productDetail);
      row.orderClosingDate = String(row.orderClosingDate);
      row.releaseDate = String(row.releaseDate);
      row.orderUnit = Number(row.orderUnit);
      row.inCtBoxQuantity = Number(row.inCtBoxQuantity);
      row.inCtPcsQuantity = Number(row.inCtPcsQuantity);
      row.banDatetime = moment(row.banDatetime, "YYYY/MM/DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
      row.productRank = String(row.productRank);
      row.unit = Number(row.unit);
      row.cafereoRemarks = String(row.cafereoRemarks);
      row.scopeType = Number(row.scopeType);
      row.scopedCustomers = Array.isArray(row.scopedCustomers)
        ? row.scopedCustomers
        : row.scopedCustomers?.split(":").filter((n) => n);
      row.updateDatetime = row.updateDate;
      row.resales;
      if (row.resales.length > 1) {
        row.resales[row.resales.length - 2].productDetail = row.resaleProductDetail;
        row.resales[row.resales.length - 2].retailPrice = row.resaleRetailPrice;
        row.resales[row.resales.length - 2].purchasePrice = row.resalePurchasePrice;
        row.resales[row.resales.length - 2].purchaseRate = row.resalePurchaseRate;
        row.resales[row.resales.length - 2].orderClosingDate = row.resaleOrderClosingDate;
        row.resales[row.resales.length - 2].releaseDate = row.resaleReleaseDate;
        row.resales[row.resales.length - 2].orderUnit = row.resaleOrderUnit;
        row.resales[row.resales.length - 2].unit = row.resaleUnit;
        row.resales[row.resales.length - 2].banDatetime = moment(row.resaleBanDatetime, "YYYY/MM/DD HH:mm").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      return row;
    },
    async onCellValueChanged(params) {
      console.log("onCellValueChanged");
      console.log(params);
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "productType") {
        params.data.updateProductType = true;
      } else if (params.colDef.field == "productRank") {
        params.data.updateProductRank = true;
      } else if (params.colDef.field == "title") {
        params.data.updateTitle = true;
      } else if (params.colDef.field == "retailPrice") {
        params.data.updateRetailPrice = true;
        await this.computePurchasePrice(params.data);
      } else if (params.colDef.field == "purchaseRate") {
        params.data.updatePurchaseRate = true;
        await this.computePurchasePrice(params.data);
      } else if (params.colDef.field == "consumptionTaxRate") {
        params.data.updateConsumptionTaxRate = true;
      } else if (params.colDef.field == "productDetail") {
        params.data.updateProductDetail = true;
      } else if (params.colDef.field == "orderClosingDate") {
        params.data.updateOrderClosingDate = true;
      } else if (params.colDef.field == "releaseDate") {
        params.data.updateReleaseDate = true;
      } else if (params.colDef.field == "unit") {
        params.data.updateUnit = true;
      } else if (params.colDef.field == "orderUnit") {
        params.data.updateOrderUnit = true;
      } else if (params.colDef.field == "inCtBoxQuantity") {
        params.data.updateInCtBoxQuantity = true;
      } else if (params.colDef.field == "inCtPcsQuantity") {
        params.data.updateInCtPcsQuantity = true;
      } else if (params.colDef.field == "banDatetime") {
        params.data.updateBanDatetime = true;
      } else if (params.colDef.field == "salesAgencyName") {
        params.data.updateSalesAgencyName = true;
      } else if (params.colDef.field == "salesOriginName") {
        params.data.updateSalesOriginName = true;
      } else if (params.colDef.field == "categoryId") {
        params.data.updateCategoryId = true;
      } else if (params.colDef.field == "cafereoRemarks") {
        params.data.updateCafereoRemarks = true;
      } else if (params.colDef.field == "stockStatus") {
        params.data.updateStockStatus = true;
      } else if (params.colDef.field == "scopeType") {
        params.data.updateScopeType = true;
      } else if (params.colDef.field == "scopedCustomers") {
        params.data.updateScopedCustomers = true;
      }
      // ここから再販
      if (params.data.resales[params.data.resales.length - 2].productStatus < BaseProductStatus.ADDITIONALRECEPTION) {
        if (params.colDef.field == "resaleProductDetail") {
          params.data.updateResaleProductDetail = true;
        } else if (params.colDef.field == "resaleRetailPrice") {
          params.data.updateResaleRetailPrice = true;
          await this.computeResalePurchasePrice(params.data);
        } else if (params.colDef.field == "resalePurchaseRate") {
          params.data.updateResalePurchaseRate = true;
          await this.computeResalePurchasePrice(params.data);
        } else if (params.colDef.field == "resaleOrderClosingDate") {
          params.data.updateResaleOrderClosingDate = true;
        } else if (params.colDef.field == "resaleReleaseDate") {
          params.data.updateResaleReleaseDate = true;
        } else if (params.colDef.field == "resaleOrderUnit") {
          params.data.updateResaleOrderUnit = true;
        } else if (params.colDef.field == "resaleUnit") {
          params.data.updateResaleUnit = true;
        } else if (params.colDef.field == "resaleBanDatetime") {
          params.data.updateResaleBanDatetime = true;
        }
      } else {
        return;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index, column) {
      if (column === this.constant.productBulkEditColumns.SCOPE_TYPE) {
        this.settingSources[index].value = this.ScopeTypes.NONE;
      } else {
        this.settingSources[index].value = "";
      }
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    async onBtnSetUpdate() {
      this.loadingOn();
      if (this.validate()) {
        for (const row of this.selectedRows) {
          for (const source of this.settingSources) {
            if (source.column === this.constant.productBulkEditColumns.PRODUCT_TYPE) {
              row.productType = source.value;
              row.updateProductType = true;
            } else if (source.column === this.constant.productBulkEditColumns.CATEGORY_NAME) {
              row.categoryId = source.value;
              row.categoryName = this.categoryNames;
              row.updateCategoryId = true;
            } else if (source.column === this.constant.productBulkEditColumns.TITLE) {
              row.title = source.value;
              row.updateTitle = true;
            } else if (source.column === this.constant.productBulkEditColumns.SALES_ORIGIN_NAME) {
              row.salesOriginName = source.value;
              row.updateSalesOriginName = true;
            } else if (source.column === this.constant.productBulkEditColumns.SALES_AGENCY_NAME) {
              row.salesAgencyName = source.value;
              row.updateSalesAgencyName = true;
            } else if (source.column === this.constant.productBulkEditColumns.RETAIL_PRICE) {
              row.retailPrice = source.value;
              row.updateRetailPrice = true;
            } else if (source.column === this.constant.productBulkEditColumns.PURCHASE_RATE) {
              row.purchaseRate = source.value;
              row.updatePurchaseRate = true;
            } else if (source.column === this.constant.productBulkEditColumns.CONSUMPTION_TAX_RATE) {
              row.consumptionTaxRate = source.value;
              row.updateConsumptionTaxRate = true;
            } else if (source.column === this.constant.productBulkEditColumns.PRODUCT_DETAIL) {
              row.productDetail = source.value;
              row.updateProductDetail = true;
            } else if (source.column === this.constant.productBulkEditColumns.CAFEREO_CLOSING_DATE) {
              row.orderClosingDate = source.value;
              row.cafereoClosingDate = moment(row.orderClosingDate, "YYYY-MM-DD").subtract(2, "days");
              row.updateOrderClosingDate = true;
            } else if (source.column === this.constant.productBulkEditColumns.RELEASE_DATE) {
              row.releaseDate = source.value;
              row.updateReleaseDate = true;
            } else if (source.column === this.constant.productBulkEditColumns.BAN_DATETIME) {
              if (row.productStatus == BaseProductStatus.APPROVED) {
                row.banDatetime = source.value;
                row.updateBanDatetime = true;
              }
            } else if (source.column === this.constant.productBulkEditColumns.STOCK_STATUS) {
              row.stockStatus = !source.value ? false : source.value;
              row.updateStockStatus = true;
            } else if (source.column === this.constant.productBulkEditColumns.ORDER_UNIT) {
              row.orderUnit = source.value;
              row.updateOrderUnit = true;
            } else if (source.column === this.constant.productBulkEditColumns.IN_CT_PCS_QUANTITY) {
              row.inCtPcsQuantity = source.value;
              row.updateInCtPcsQuantity = true;
            } else if (source.column === this.constant.productBulkEditColumns.IN_CT_BOX_QUANTITY) {
              row.inCtBoxQuantity = source.value;
              row.updateInCtBoxQuantity = true;
            } else if (source.column === this.constant.productBulkEditColumns.UNIT) {
              row.unit = source.value;
              row.updateUnit = true;
            } else if (source.column === this.constant.productBulkEditColumns.PRODUCT_RANK) {
              row.productRank = source.value;
              row.updateProductRank = true;
            } else if (source.column === this.constant.productBulkEditColumns.SCOPE_TYPE) {
              row.scopeType = source.value;
              row.updateScopeType = true;
              row.scopedCustomers = this.scopedCustomers;
              row.updateScopedCustomers = true;
            } else if (source.column === this.constant.productBulkEditColumns.CAFEREO_REMARKS) {
              row.cafereoRemarks = source.value;
              row.updateCafereoRemarks = true;
            }
            if (row.updateRetailPrice || row.updatePurchaseRate) await this.computePurchasePrice(row);

            // ここから再販
            if (
              row.resales.length > 1 &&
              row.resales[row.resales.length - 2].productStatus < BaseProductStatus.ADDITIONALRECEPTION
            ) {
              if (source.column === this.constant.productBulkEditColumns.RESALE_RETAIL_PRICE) {
                row.resaleRetailPrice = source.value;
                row.updateResaleRetailPrice = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_PURCHASE_RATE) {
                row.resalePurchaseRate = source.value;
                row.updateResalePurchaseRate = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_PRODUCT_DETAIL) {
                row.resaleProductDetail = source.value;
                row.updateResaleProductDetail = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_CAFEREO_CLOSING_DATE) {
                row.resaleOrderClosingDate = source.value;
                //row.cafereoClosingDate = moment(row.orderClosingDate, "YYYY-MM-DD").subtract(2, "days");
                row.updateResaleOrderClosingDate = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_RELEASE_DATE) {
                row.resaleReleaseDate = source.value;
                row.updateResaleReleaseDate = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_BAN_DATETIME) {
                if (row.productStatus == BaseProductStatus.APPROVED) {
                  row.resaleBanDatetime = source.value;
                  row.updateResaleBanDatetime = true;
                }
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_ORDER_UNIT) {
                row.resaleOrderUnit = source.value;
                row.updateResaleOrderUnit = true;
              } else if (source.column === this.constant.productBulkEditColumns.RESALE_UNIT) {
                row.resaleUnit = source.value;
                row.updateResaleUnit = true;
              }
              if (row.updateResaleRetailPrice || row.updateResalePurchaseRate)
                await this.computeResalePurchasePrice(row);
            }
          }
        }
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
        this.loadingOff();
      }
    },
    // ****************************
    // 共通業務処理
    // ****************************
    // 仕入価格計算
    async computePurchasePrice(row) {
      if (!isNaN(Number(row.retailPrice)) && !isNaN(parseFloat(row.purchaseRate)) && row.supplierCd) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: row.retailPrice,
          purchaseRate: row.purchaseRate,
          supplierCd: row.supplierCd,
        });
        row.purchasePrice = response.data.contents.purchasePrice;
      }
    },

    async computeResalePurchasePrice(row) {
      if (!isNaN(Number(row.resaleRetailPrice)) && !isNaN(parseFloat(row.resalePurchaseRate)) && row.supplierCd) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: row.resaleRetailPrice,
          purchaseRate: row.resalePurchaseRate,
          supplierCd: row.supplierCd,
        });
        row.resalePurchasePrice = response.data.contents.purchasePrice;
      }
    },

    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.productBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      if (this.isCafereoUser) {
        // 商品ランク
        this.setValidMessage(this.rules.required(row.productRank), "商品ランク", messages);
        // 上代
        this.setValidMessage(this.rules.isNumber(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.maxLength(10)(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.required(row.retailPrice), "上代", messages);
        // 仕入価格
        this.setValidMessage(this.rules.isNumber(row.purchasePrice), "仕入価格", messages);
        this.setValidMessage(this.rules.required(row.purchasePrice), "仕入価格", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.purchasePrice), "仕入価格", messages);
        // 仕入掛率
        this.setValidMessage(this.rules.isRate(row.purchaseRate), "仕入掛率", messages);
        this.setValidMessage(this.rules.required(row.purchaseRate), "仕入掛率", messages);

        // 受注単位
        this.setValidMessage(this.rules.required(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.isNumber(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.orderUnit), "受注単位", messages);
        // Cafereo備考
        this.setValidMessage(this.rules.maxLength(300)(row.cafereoRemarks), "Cafereo備考", messages);

        // 再販前 商品説明
        this.setValidMessage(this.rules.maxLength(300)(row.resaleProductDetail), "再販前 商品説明", messages);
        // 再販前 発注締日
        this.setValidMessage(this.rules.required(row.resaleOrderClosingDate), "再販前 発注締日", messages);
        // 再販前 発売日
        this.setValidMessage(this.rules.required(row.resaleReleaseDate), "再販前 発売日", messages);
        // 再販前 上代
        this.setValidMessage(this.rules.isNumber(row.resaleRetailPrice), "再販前 上代", messages);
        this.setValidMessage(this.rules.maxLength(10)(row.resaleRetailPrice), "再販前 上代", messages);
        this.setValidMessage(this.rules.required(row.resaleRetailPrice), "再販前 上代", messages);
        // 再販前 仕入価格
        this.setValidMessage(this.rules.isNumber(row.resalePurchasePrice), "再販前 仕入価格", messages);
        this.setValidMessage(this.rules.required(row.resalePurchasePrice), "再販前 仕入価格", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.resalePurchasePrice), "再販前 仕入価格", messages);
        // 再販前 仕入掛率
        this.setValidMessage(this.rules.isRate(row.resalePurchaseRate), "再販前 仕入掛率", messages);
        this.setValidMessage(this.rules.required(row.resalePurchaseRate), "再販前 仕入掛率", messages);
        // 再販前 受注単位
        this.setValidMessage(this.rules.required(row.resaleOrderUnit), "再販前 受注単位", messages);
        this.setValidMessage(this.rules.isNumber(row.resaleOrderUnit), "再販前 受注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.resaleOrderUnit), "再販前 受注単位", messages);
        // 再販前 発注単位
        this.setValidMessage(this.rules.isNumber(row.resaleUnit), "再販前 発注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.resaleUnit), "再販前 発注単位", messages);
        // 再販前 情報解禁日時
        this.setValidMessage(this.rules.required(row.resaleBanDatetime), "再販前 情報解禁日時", messages);
      }

      // 商品区分
      this.setValidMessage(this.rules.required(row.productType), "商品区分", messages);
      // タイトル
      const tmpTitle = row.title?.label
        ? String(row.title.label)
        : typeof row.title === "string" || row.title instanceof String
        ? row.title
        : "";
      this.setValidMessage(this.rules.required(tmpTitle), "タイトル", messages);
      this.setValidMessage(this.rules.maxByteLengthSjis(60)(tmpTitle), "タイトル", messages);

      // 消費税
      this.setValidMessage(this.rules.required(row.consumptionTaxRate), "消費税", messages);
      // 商品説明
      this.setValidMessage(this.rules.maxLength(300)(row.productDetail), "商品説明", messages);
      // 発注締日
      this.setValidMessage(this.rules.required(row.orderClosingDate), "発注締日", messages);
      // 発売日
      this.setValidMessage(this.rules.required(row.releaseDate), "発売日", messages);

      // 発注単位
      const unitName = this.isCafereoUser ? "発注単位" : "受注単位";
      this.setValidMessage(this.rules.isNumber(row.unit), unitName, messages);
      this.setValidMessage(this.rules.maxLength(8)(row.unit), unitName, messages);
      // 情報解禁日時
      this.setValidMessage(this.rules.required(row.banDatetime), "情報解禁日時", messages);
      // 発売元
      this.setValidMessage(this.rules.required(row.salesAgencyName), "発売元", messages);
      this.setValidMessage(this.rules.maxLength(30)(row.salesAgencyName), "発売元", messages);
      // 販売元
      this.setValidMessage(this.rules.required(row.salesOriginName), "販売元", messages);
      this.setValidMessage(this.rules.maxLength(30)(row.salesOriginName), "販売元", messages);
      // カテゴリー
      this.setValidMessage(this.rules.required(row.categoryId), "カテゴリー", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    validUnitPrice(row) {
      if (row == null) return true;
      if (row.retailPrice < row.unitPrice) return "仕入価格が上代を超えています。";
      return true;
    },
    validPurchaseQuantity(row) {
      if (row == null) return true;
      if (row.purchaseQuantity < row.unit) return "仕入数が発注単位を満たしていません。";
      return true;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    // ***** ユーティリティ *****
    toCamelCase(str) {
      return str
        .split("_")
        .map(function (word, index) {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join("");
    },
    // スネークケースからキャメルケースに変換（オブジェクト）.
    toCamelCaseObject(obj) {
      const result = {};
      Object.keys(obj).forEach((key) => {
        result[this.toCamelCase(key)] = obj[key];
      });
      return result;
    },
    onCategoryChanged() {
      const category = this.$refs.category[0]?.selected();
      if (category) {
        this.categoryNames = category.categoryName;
      }
    },
    async onAppendOverseasImpossible() {
      try {
        this.btnDisabled = true;
        this.loadingOn();
        const response = await this.$store.dispatch("customer/overseasImpossible");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendOverseasImpossible", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOff();
      }
    },
    async onAppendTohoLimited() {
      try {
        this.btnDisabled = true;
        this.loadingOn();
        const response = await this.$store.dispatch("customer/tohoLimited");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendTohoLimited", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOff();
      }
    },
    async disclosureRangeProc(customers) {
      // 更新モデルとグリッドに追加（チェックはonRowDataで実施）
      let corpChange = false;
      customers.forEach((row) => {
        if (!this.scopedCustomers.includes(row.customerCd)) {
          this.scopedCustomers.push(row.customerCd);
        }
        if (!this.scopeCorporateCodes.includes(row.corporationCd)) {
          this.scopeCorporateCodes.push(row.corporationCd);
          corpChange = true;
        }
      });
      if (!corpChange) {
        this.findCustomers(this.scopeCorporateCodes);
      }
    },
    onCellEditingStarted(event) {
      console.log("cellEditingStarted " + event.column.colId);
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    scopedCustomersChanged(value) {
      console.log(value);
      this.scopeCorporateCodes = [];
      this.scopedCustomerRecords = [];
      this.scopedCustomers = [];
    },
  },

  async beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      maxWidth: 300,
      resizable: true,
      sortable: true,
      suppressSizeToFit: false,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = await this.setColumnDefs();
    if (this.isCafereoUser) {
      this.constant.productBulkEditColumnsValues = CafereoProductBulkEditColumns.all();
      this.constant.productBulkEditColumns = CafereoProductBulkEditColumns;
    } else {
      this.constant.productBulkEditColumnsValues = SupplierProductBulkEditColumns.all();
      this.constant.productBulkEditColumns = SupplierProductBulkEditColumns;
    }
  },
};
</script>
